<template>
  <div class="mb-2 sub-menu">
    <b-button
      :variant="
        $route.meta.navActiveTab == menu.active ? `primary` : `outline-primary`
      "
      v-for="menu in menus"
      :key="`menu-${menu.routeName}`"
      class="mr-1"
      @click="$router.push({ name: menu.routeName })"
    >
      {{ $t(menu.label) }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
  data() {
    return {
      menus: [
        {
          routeName: "bet-setting",
          active: "bet-setting",
          label: "breadcrumb.betSetting",
        },
        {
          routeName: "round-up-odds",
          active: "round-up-odds",
          label: "breadcrumb.roundUpOdds",
        },
        {
          routeName: "limit-odds",
          active: "limit-odds",
          label: "breadcrumb.limitOdds",
        },
        {
          routeName: "list-tool",
          active: "tool",
          label: "breadcrumb.toolSetting",
        },
        {
          routeName: "list-time",
          active: "time",
          label: "breadcrumb.timeSetting",
        },
      ],
    };
  },
  setup() {},
};
</script>